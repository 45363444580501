
  export const style={
    inpuStyle:{
      colorInput:"#BA1218"
    },
    url:{
      urlClient:"https://foton.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/logo_primary_foton.png",
      secondaryLogonLogo: "null",
    },
    CurrencyName:{
      name:"Ponto/Pontos"
    },
    teamName:{
      name:"Foton"
    },
    hasToken: false,
    hostHeader: "foton"
  }
